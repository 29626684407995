import React, { useState, useEffect, useMemo } from 'react';
import '../../App.css';
import '../../styles/public.css'
import './LogsPage.css';
import { Layout, Pagination, Breadcrumb, Skeleton } from 'antd';
import TableComponent from '../../components/table/TableComponent';
import { useQuery, useQueryClient } from 'react-query';
import { logsApi } from '../../api/logsHandler';
import RequestOverView from './RequestOverView';
import IntegratedSearch from '../../components/search/IntegratedSearch';
import Calendar from 'react-calendar/dist/cjs/Calendar.js';
import 'react-calendar/dist/Calendar.css';
import { utils } from '../../common/utils';

function WebLogsPage() {
  const pageSize = 25;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //테이블 선택한 row keys
  const [page, setPage] = useState(1); //현재 페이지 번호
  const [showSearchFilter, setShowSearchFilter] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchLoading, setSearchLoading] = useState(false); //검색했을때 로딩
  const [startDate, setStartDate] = useState(utils.formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - 1)))); // 검색 시작일 (어제)
  const [endDate, setEndDate] = useState(utils.formatDateToYYYYMMDD(new Date())); // 검색 종료일 (오늘)

  const queryClient = useQueryClient();

  const { data: logResult,
    isLoading,
    isFetching,
    isError,
    refetch: refetchApiLog,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useQuery(
    ['apiWebLogList', page],
    () => logsApi.fetchWebApiLogList({
      page,
      pageSize,
      searchKeyword,
      startDate,
      endDate,
    }),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      onError: (error) => {
        console.error('Error fetching notifications:', error);
      },
      refetchInterval: showSearchFilter || searchKeyword.length > 0 ? false : 2000, // 2 seconds
      getNextPageParam: (lastPage) => lastPage.nextPage ?? false, // Get next page param for infinite queries
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? false, // Get previous page param for infinite queries
    }
  );

  const tableColumns = useMemo(() => [
    { label: 'idx', key: 'idx', width: '0%', textAlign: 'left', hidden: true },
    { label: '요청시간', key: 'access_date', width: '10%', textAlign: 'left' },
    { label: '회사명', key: 'company_name', width: '7%', textAlign: 'left' },
    { label: '사용자명', key: 'user_name', width: '7%', textAlign: 'left' },
    { label: '요청URL', key: 'full_url', width: '15%', textAlign: 'left' },
    { label: '아이피', key: 'host_ip', width: '10%', textAlign: 'left' },
  ], []);

  useEffect(() => {
    // 현재페이지 키보드 이벤트
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    //검색이 끝나면 loading은 무조건 제거
    if (!isFetching) {
      setSearchLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    //클릭한 페이지 요청
    refetchApiLog();

    //현재 페이징 기준 앞/뒤 10페이지씩 prefetch
    if (page > 3) {
      for (let i = 1; i <= 10; i++) {
      queryClient.prefetchQuery(['apiWebLogList', page + i], () => logsApi.fetchWebApiLogList({
        page: page + i,
        pageSize,
        searchKeyword,
        startDate,
        endDate,
      }));
      // 페이지가 음수인 경우는 prefetch하지 않음
      if (page - i > 0) {
        queryClient.prefetchQuery(['apiWebLogList', page - i], () => logsApi.fetchWebApiLogList({
        page: page - i,
        pageSize,
        searchKeyword,
        startDate,
        endDate,
        }));
      }
      }
    }
  }, [page]);

  useEffect(() => {
    console.log('endDate', endDate);
  }, [endDate]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClickSearch();
    }
  };

  //검색필터 펼치기
  const handleShowSearchFilter = (props) => {
    setShowSearchFilter(!showSearchFilter);
  };

  //통합검색 버튼 클릭
  const handleClickSearch = () => {
    setSearchLoading(true);
    //페이징이 변경되면 useEffect에서 API 호출
    refetchApiLog();
  };

  //검색어 변경
  const handleChangeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  //날짜 조건 검색 변경 이벤트
  const handleChangeDate = (event, type) => {
    const value = new Date(event.target.value);
    const formattedDate = value.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    if (type === 'start') {
      if (value > endDate) {
        alert('시작일은 종료일보다 클 수 없습니다.');
        return;
      }
      setStartDate(formattedDate);
    } else {
      setEndDate(formattedDate);
    }
  };

  //onClick 조건 검색 이벤트
  const handleClickConditionSearch = () => {
    //검색조건에 따라 검색
    setSearchLoading(true);
    refetchApiLog();
  };

  //리스트 데이터 정렬 변경
  const convertListDesc = (data) => {
    return data.sort((a, b) => new Date(b.access_date) - new Date(a.access_date));
  };

  //검색어 초기화
  const SearchFilterComponent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: 20 }}>
        <div className="search_filter_item">
          <div className="search_item_title">
            <span>검색기간</span>
          </div>
          <div className="search_item_area">
            <div className="intergrated_search_wrap log_table_search_input">
              <input type="date" value={startDate} onChange={(event) => handleChangeDate(event, 'start')} onFocus={(e) => e.target.showPicker()} />
            </div>
            ~
            <div className="intergrated_search_wrap log_table_search_input">
              <input type="date" className="hasDatePicker" value={endDate} onChange={(event) => handleChangeDate(event, 'end')} onFocus={(e) => e.target.showPicker()} />
            </div>
          </div>
        </div>
        <div className="search_filter_item"></div>
        <div className="search_filter_actions" style={{ marginTop: 'auto', width: '100%', display: 'flex', flexDirection: 'row', gap: 10 }}>
          <div className="default_btn" style={{ width: '50%', height: 40 }}>초기화</div>
          <div className="primary_btn" onClick={handleClickConditionSearch} style={{ width: '50%', height: 40 }}>검색</div>
        </div>
      </div>
    );
  };

  if (isError) {
    return (
      <Layout style={{ width: '100%', padding: '0 24px 24px' }}>
        <div style={{ color: 'blue' }}>오류</div>
      </Layout>
    );
  }

  return (
    <Layout style={{ padding: '0px 20px', overflow: 'auto' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>실시간 로그</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
        <Breadcrumb.Item>App</Breadcrumb.Item>
      </Breadcrumb>
      <div className="first_wrap" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <span className="title_text" style={{ marginBottom: 8 }}>OverView</span>
        <RequestOverView startDate = {startDate} endDate = {endDate} searchKeyword = {searchKeyword} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 12, margin: '0px 0px 100px 0px' }}>
        {searchLoading && (
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}>
            <span>검색 결과를 분석하고 있습니다.</span>
          </div>
        )}
        <div style={{ marginBottom: 20, height: '100%' }}>
          <IntegratedSearch
            searchKeyword={searchKeyword}
            handleChangeSearchKeyword={handleChangeSearchKeyword}
            handleClickSearch={handleClickSearch}
            showSearchFilter={showSearchFilter}
            handleShowSearchFilter={handleShowSearchFilter}
            placeholder={'통합 검색'}
            filterComponent={<SearchFilterComponent />}
          />
        </div>
        <div id="api_logs_table" className="contents_box" style={{ height: 950 }}>
          {
            isLoading ?
              <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
                <Skeleton active />
                <Skeleton active />
              </div> :
              <>
                <TableComponent
                  showCheckbox={false}
                  columns={tableColumns}
                  data={convertListDesc(logResult?.list) || []}
                  loading={isLoading}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (keys) => setSelectedRowKeys(keys),
                  }} />
                {logResult?.list?.length > 0 && (
                  <Pagination
                    current={page}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '50px',
                      marginTop: 20
                    }}
                    pageSize={pageSize}
                    total={logResult?.total || 0}
                    onChange={(page) => setPage(page)}
                  />
                )}
              </>
          }
        </div>
      </div>
    </Layout>
  );
}

export default WebLogsPage;
