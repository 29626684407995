// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/x_btn.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (prefers-color-scheme: dark) {
    .modal_close_btn:hover {
        border-radius: 4px;
        background-color: #393939;
    }
    .default_btn {
        border: 0.4px solid #eaeaea;
    }
}

@media (prefers-color-scheme: light) {
    .modal_close_btn:hover {
        border-radius: 4px;
        background-color: #e8e8e8;
    }
    .default_btn {
        border: 0.4px solid #eaeaea;
    }
} 

.modal_close_btn {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 28px !important;
    height: 28px !important;
}

.default_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    /* border: 0.1px solid #eaeaea; */
    /* background-color: #0098fe; */
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
}

.primary_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: #0098fe;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/button.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,yBAAyB;IAC7B;IACA;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,yBAAyB;IAC7B;IACA;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,gDAA0C;IAC1C,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,iCAAiC;IACjC,+BAA+B;IAC/B,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":["@media (prefers-color-scheme: dark) {\n    .modal_close_btn:hover {\n        border-radius: 4px;\n        background-color: #393939;\n    }\n    .default_btn {\n        border: 0.4px solid #eaeaea;\n    }\n}\n\n@media (prefers-color-scheme: light) {\n    .modal_close_btn:hover {\n        border-radius: 4px;\n        background-color: #e8e8e8;\n    }\n    .default_btn {\n        border: 0.4px solid #eaeaea;\n    }\n} \n\n.modal_close_btn {\n    content: url('../assets/images/x_btn.png');\n    width: 28px !important;\n    height: 28px !important;\n}\n\n.default_btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 14px;\n    border-radius: 4px;\n    /* border: 0.1px solid #eaeaea; */\n    /* background-color: #0098fe; */\n    color: #FFFFFF;\n    font-size: 16px;\n    font-weight: bold;\n    box-sizing: border-box;\n    cursor: pointer;\n}\n\n.primary_btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 14px;\n    border-radius: 4px;\n    border: none;\n    background-color: #0098fe;\n    color: #FFFFFF;\n    font-size: 16px;\n    font-weight: bold;\n    box-sizing: border-box;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
