import React,{useEffect} from 'react';
import '../App.css';
import { Route, Routes } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import './PagesLayout.css';
import SubMenu from '../components/menus/SubMenu';
import AppLogsPage from './logs/AppLogsPage';
import StatusBar from '../components/menus/StatusBar';
import TestPage from './test/TestPage';
import WebLogsPage from './logs/WebLogsPage';

/*
 * Layout component that renders a layout with a span element.
 * @component
 * @returns {JSX.Element} The rendered Layout component.
 */

function PagesLayout() {
  const { pathname } = window.location;
  console.log('Current URL path:', pathname);

  return (
    <div style = {{height:"100vh"}}>
      <StatusBar/>
      <div class="public_layout">
      {/* 좌측 메뉴 */}
      <SubMenu />
        <Routes>
          <Route path="/app/logger.do" element={<AppLogsPage />} />
          <Route path="/web/logger.do" element={<WebLogsPage />} />
          <Route path="/test.do" element={<TestPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default PagesLayout;
