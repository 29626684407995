// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusBar {
   height: 52px;
   /* background: blue; */
   color: white;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0px 20px;
   -webkit-app-region: drag;
   border-bottom: 0.4px solid #e2e2e2;
 }

 .statusBar:active{
   /* background-color: red; */
}

 .statusBar span {
   font-size: 14px;
   font-weight: 500;
   color: white;
 }

 .right_menu_grp{
   display: flex;
   align-items: center;
   gap:12px;
   -webkit-app-region: no-drag;
 }

 .right_menu_grp .status_default_btn_wrap{
    display: flex;
    gap:12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-right: 4px;

 }`, "",{"version":3,"sources":["webpack://./src/components/menus/StatusBar.css"],"names":[],"mappings":"AAAA;GACG,YAAY;GACZ,sBAAsB;GACtB,YAAY;GACZ,aAAa;GACb,mBAAmB;GACnB,8BAA8B;GAC9B,iBAAiB;GACjB,wBAAwB;GACxB,kCAAkC;CACpC;;CAEA;GACE,2BAA2B;AAC9B;;CAEC;GACE,eAAe;GACf,gBAAgB;GAChB,YAAY;CACd;;CAEA;GACE,aAAa;GACb,mBAAmB;GACnB,QAAQ;GACR,2BAA2B;CAC7B;;CAEA;IACG,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;;CAErB","sourcesContent":[".statusBar {\n   height: 52px;\n   /* background: blue; */\n   color: white;\n   display: flex;\n   align-items: center;\n   justify-content: space-between;\n   padding: 0px 20px;\n   -webkit-app-region: drag;\n   border-bottom: 0.4px solid #e2e2e2;\n }\n\n .statusBar:active{\n   /* background-color: red; */\n}\n\n .statusBar span {\n   font-size: 14px;\n   font-weight: 500;\n   color: white;\n }\n\n .right_menu_grp{\n   display: flex;\n   align-items: center;\n   gap:12px;\n   -webkit-app-region: no-drag;\n }\n\n .right_menu_grp .status_default_btn_wrap{\n    display: flex;\n    gap:12px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    padding-right: 4px;\n\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
