import React, { useRef, useEffect, useState } from 'react';
import { ErrorHandler } from '../../../common/error';
import './ApprEditorPage.css'

// 전자결재 에디터 작성 화면
// 작성자 최성우
function ApprEditorPage() {
  const autoFocus = true; //자동 포커스 여부
  const errorHandler = new ErrorHandler(); //에러 핸들러
  const queryParams = new URLSearchParams(window.location.search);
  const fullsize = queryParams.get('fullsize'); //읽기모드인지 여부 
  const typeCode = queryParams.get('typeCode'); //문서 종류 템플릿 번호
  const editorRef = useRef();
  const [resizeHeight, setResizeHeight] = useState(0);
  const [editorContent,setEditorContent] = useState('');

  //최초 로딩
  useEffect(() => {
    // if (!queryParams.has('test')) {
    //   for(const i = 0; i < 1000; i++) {
    //     alert(document.querySelector('.tox-statusbar'))
    //   // errorHandler.goToErrorPage();
    //   // return;
    //   }
    // }
    if (!queryParams.has('fullsize')) {
      errorHandler.logError('fullsize 파라미터는 필수 키 입니다.');
      errorHandler.goToErrorPage();
      return;
    }
    //에디터 초기화
    initEditor();
  }, []);


  // 키보드 내려감을 감지
  useEffect(() => {
    const handleResize = () => {
      const isKeyboardVisible = window.visualViewport && window.visualViewport.height < window.innerHeight;
      if (!isKeyboardVisible) {
         postAppMessage('getEditorContent');
      }
    };

    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);


  const initEditor = () => {
    window.tinymce.init({
      selector: '#editor',
      language: 'ko_KR',
      // height: 'calc(100% + 120px)',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime media table paste imagetools wordcount',
      ],
      menubar: false,
      // width:'700px',
      overflow: 'auto',
      height: getMaxHeight(),
      table_default_styles: {
        width:'756px',
        border : '1',
      },
      language: "ko_KR",
      // toolbar: 'bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
      toolbar: 'fontsizeselect | bold italic forecolor backcolor table | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fullpage',
      setup: function (ed) {
        // if (isRead === 'N') {
        ed.on('click', function (evt) {
          postAppMessage('editor_focus');
        });
        ed.on('keyup', function (evt) {
          setEditorContent(window.tinymce.activeEditor.getContent());
        });
        ed.on('init', function (evt) {
          if (typeCode === '10') {
            ed.setContent(
              `<table style="width:956px; margin-left: auto; margin-right: auto; height: 132px;" border="1" >
								<tbody>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>NO</strong></td>
								<td style="width: 16.6666%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>거래처</strong></td>
								<td style="width: 28.3754%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>적요</strong></td>
								<td style="width: 12.9504%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>금액</strong></td>
								<td style="width: 21.4934%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>계좌정보</strong></td>
								<td style="width: 15.3901%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>비고</strong></td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 50.1662%; height: 22px; text-align: center; background-color: #e5e5e5;" colspan="3"><strong>합계</strong></td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 15.3901%; text-align: center; height: 22px;">&nbsp;</td>
								</tr>
								</tbody>
							</table>`
            );
          }
        });
      }
    }).then(async (result) => {
      //모바일에서는 기본 줌을 0.6
      const text_edit_area = document.querySelector('.tox-edit-area iframe');
      const innerDoc = text_edit_area?.contentDocument || text_edit_area?.contentWindow.document;
      const body = innerDoc?.querySelector('body');
      // body.style.zoom = 1


      if (document.querySelector('.tox-statusbar')) {
        document.querySelector('.tox-statusbar').style.display = 'none';
      }

      if (document.querySelector('.tox .tox-toolbar') ) {
        document.querySelector('.tox .tox-toolbar').style.display = 'flex';
        document.querySelector('.tox .tox-toolbar').style.overflow = 'auto';
        document.querySelector('.tox .tox-toolbar').style.width = '100%';
        document.querySelector('.tox .tox-toolbar').style.flexWrap = 'nowrap';

        document.querySelectorAll('.tox-toolbar .tox-toolbar__group')?.forEach(group => {
          group.style.display = 'flex';
          group.style.flexWrap = 'nowrap';
        });
      }

      if(document.querySelector('.tox-sidebar-wrap')) {
        document.querySelector('.tox-sidebar-wrap').style.overflow = 'auto';
      }
      if (document.querySelector('.tox-tinymce')) {
        document.querySelector('.tox-tinymce').style.border = '0';
      }


      //메뉴 박스가 모바일화면에서 작아지는거 강제로 처리;
      //옵저버로 에디터가 init 되는 순간부터 tox-tinymce-aux class 를 detect해서 처리
      //에디터 자체 이벤트 리스너 제공 함수를 찾지못해서 ... 일단 이렇게 처리함; 24.12.09 최성우
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const selectedMenuElements = document.querySelectorAll('.tox-menu');

            selectedMenuElements.forEach(menuElement => {
              console.log('menuElement',menuElement)
                menuElement.style.minWidth = '132px';
            });
          }
        }
      });

      const targetNode = document.querySelector('.tox-tinymce-aux');
      if (targetNode) {
        observer.observe(targetNode, { childList: true, subtree: true });
      }
      //강제 옵저버 종료

      receiveAppMessage();

      //에디터 로딩 완료시 앱으로 메시지 전달
      setTimeout(() => {
        postAppMessage('editor_loaded');
      }, 100)
    });
  };

  //에디터 최대 높이
  const getMaxHeight = () => {
    const isKeyboardVisible = window.visualViewport && window.visualViewport.height < window.innerHeight;
    //키보드가 올라온경우 에디터 최대 높이를 조절함 (키보드에 내용이 가려지지 않도록)
    // return 2000;
    if (isKeyboardVisible) {
      return window.visualViewport.height - 58;
    }
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  };

  // 에디터가 초기화가 완료됐을때 앱에서 오는 메시지를 수신해서 에디터 컨텐츠를 동적으로 변환함.
  const receiveAppMessage = () => {

    const messageHandler = (event) => {
      try {
        if (!event?.data) {
          return;
        }
        const receive = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        //단 첫번째 작성시에는 고정된 템플릿을 보여주기 위해 체크
        if (receive.key === 'atw_docConents') {
            if(receive.data.content.length > 0) {
              window.tinymce.activeEditor.setContent(receive.data.content);
            }
        }
        if(receive.key === 'call_open_full_size_editor') {
            postAppMessage('open_full_size_editor');
        }
      } catch (e) {
        errorHandler.logError(e.message);
      }
    };

    window.addEventListener('message', messageHandler);
    document.addEventListener('message', messageHandler); // For Android

    return () => {
      window.removeEventListener('message', messageHandler);
      document.removeEventListener('message', messageHandler); // For Android
    };
  }

  //앱 쪽으로 메시지 전달
  const postAppMessage = (messageType) => {
    let content = '';

    if (!window.ReactNativeWebView) {
      return;
    }

    switch (messageType) {
      case 'send_editor_content': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'getEditorContent': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();

        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'open_full_size_editor': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();

        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달하고 전체화면으로 전환합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'editor_focus': //isRead 모드일때 에디터 포커싱이 감지시 앱으로 포커싱 감지 메시지 전달
        content = window.tinymce.activeEditor.getContent();
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: 'isRead 모드일때 에디터 포커싱이 감지시 앱으로 포커싱 감지 메시지 전달',
          data: {
            content: content
          }
        }));
        break;
      case 'editor_loaded': //editor 로딩 완료
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '에디터 init이 완료되었을때 앱으로 전달되는 메시지',
          data: {
            content: content
          }
        }));
        break;
    }
  }

  const handleEditorZoom = (zoomIn) => {
    const text_edit_area = document.querySelector('.tox-edit-area iframe');
    const innerDoc = text_edit_area.contentDocument || text_edit_area.contentWindow.document;
    const body = innerDoc.querySelector('body');
    let currentZoom = parseFloat(body.style.zoom) || 1;
    let newZoom = zoomIn ? currentZoom + 0.1 : currentZoom - 0.1;
    if (newZoom > 1.5) newZoom = 1.5;
    if (newZoom < 0.5) newZoom = 0.3;
    body.style.zoom = newZoom;
  };

  return (
    <div className="container" style={{ display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
      {fullsize === 'Y' ?
       <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 18,
          borderBottomWidth: 1,
          borderBottomColor: '#ECECEC',
          backgroundColor: '#EFF1F7',
          padding: '8px 20px',
          borderTopWidth: 1,
          borderTopColor: '#ECECEC'
        }}>
          <span style={{ fontWeight: 500, fontSize: 15, color: '#70768D' }}>
            상세내용 <span style={{ fontWeight: 500, fontSize: 16, color: '#FF5EA4' }}> * </span>
          </span>
          <div style = {{display:'flex',gap:8,justifyContent:'center', alignItems:'center'}}>
            <img src = "/images/arrow_btn_gray.png" />
              <span style={{ fontWeight: 400,fontSize:12 }} onClick={() => postAppMessage('send_editor_content')}> 
                접기 
              </span>
            </div>
        </div> : <></> }

  

      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
        <textarea id="editor" ref={editorRef} style={{fontFamily:'GmarketSansTTFMedium', flex: 1, overflow: 'auto' }} />
      </div>
      {/* {isRead === 'N' &&
        <div id="completeButton" onClick={() => postAppMessage('send_editor_content')}
          style={{ height: '58px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '19px', color: '#fff', position: 'absolute', bottom: `${resizeHeight}px`, width: '100%', background: '#0066FF', zIndex: 1 }}>
          <span>작성완료</span>
        </div>
      } */}
    </div>
  );
}

export default ApprEditorPage;
