import React, { useEffect, useState } from 'react';
import { Menu, Switch, Layout, theme } from 'antd';
import './StatusBar.css'
import '../../styles/public.css'
import '../../styles/button.css'

const StatusBar = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSystemTheme, setIsSystemTheme] = useState(false);

  //앱 완전 종료 버튼
  const handleControllApp = (action) => {
    if(action === 'close') {
      const userConfirmed = window.confirm("프로그램을 종료할까요?");
      if (!userConfirmed) {
        return;
      }
      window.electron.ipcRenderer.sendMessage('window-control', 'close')
    } else if (action === 'maximize') {
      window.electron.ipcRenderer.sendMessage('window-control', 'maximize')
    } else if(action === 'minimize') {
      window.electron.ipcRenderer.sendMessage('window-control', 'minimize')
    }
  }

  const toggleDarkMode = async () => {
    if (window.electron) {
      const darkMode = await window.darkMode.toggle();
      setIsDarkMode(darkMode);
    } else {
      const currentTheme = window.localStorage.getItem('welcoming-theme');
      const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
      window.localStorage.setItem('welcoming-theme', newTheme);
      setIsDarkMode(newTheme === 'dark');
    }
  };

  const resetToSystem = async () => {
    await window.darkMode.system();
    setIsSystemTheme(true);
    setIsDarkMode(false);
  };

  return (
    <div className="statusBar" style={{ zIndex: 9999, position: 'relative' }}>
      <div className="left_menu_grp">
        <img src="https://www.ybe.kr/img/common/logo.png" alt="logo" />
      </div>
      <div className="right_menu_grp">
        <div className="toggle-wrap" style = {{ display: window.electron ? 'block' : 'none'}}>
          <Switch
            size={"large"}
            style={{ width: 70 }}
            checked={isDarkMode}
            onChange={async () => toggleDarkMode()}
            checkedChildren="Dark"
            unCheckedChildren="Light"
          />
        </div>
        <div className='status_default_btn_wrap'>
          <div style = {{ display: window.electron ? 'block' : 'none'}} onClick={() => handleControllApp('close')}>
            <img alt="" className="modal_close_btn"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusBar;