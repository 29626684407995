import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ApprEditorPage from './webview/pages/approval/ApprEditorPage';
import LoginPage from './pages/login/LoginPage';
import PagesLayout from './pages/PagesLayout';


function App() {

 console.log('window.tinymce', window.tinymce)

  return (
    <Routes>
      {/* 레이아웃이 필요없는 개별 페이지 */}
      <Route path="/login.do" element={<LoginPage />} />
      {/* 공통레이아웃을 사용하는 페이지 */}
      <Route path="/*" element={<PagesLayout />} />
      {/* 별개의 웹뷰 화면 */}
      <Route path="/webview/appr/editor" element={<ApprEditorPage />} />

    </Routes>
  );
}

export default App;