import React, { useState, useEffect } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Switch, Layout, theme } from 'antd';
import {
  LaptopOutlined, NotificationOutlined, UserOutlined, DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './SubMenu.css';
const { Header, Content, Sider } = Layout;

const SubMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();


  return (
    <div className="submenu-layout">
      <div className="sub-top-container">

      </div>
      <div className='menu_nav_container'>
        <div className="item_title">
          <span>Log Analysis</span>
        </div>

        <div
          className={`item_box ${location.pathname === '/app/logger.do' ? 'select_item' : ''}`}
          onClick={() => navigate('/app/logger.do')}
        >
          <LaptopOutlined />
          <span>Requst 로그(모바일)</span>
        </div>

        <div
          className={`item_box ${location.pathname === '/web/logger.do' ? 'select_item' : ''}`}
          onClick={() => navigate('/web/logger.do')}
        >
          <LaptopOutlined />
          <span>Requst 로그(웹)</span>
        </div>

        <div
          className={`item_box ${location.pathname === '/test.do' ? 'select_item' : ''}`}
          onClick={() => navigate('/test.do')}
        >
          <LaptopOutlined />
          <span>테스트</span>
        </div>


      </div>
    </div>
  );
};

export default SubMenu;
