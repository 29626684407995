/**
 * Class representing an error handler.
 */
/**
 * 에러 핸들러 클래스.
 */
export class ErrorHandler {
    /**
     * 에러 핸들러를 생성합니다.
     */
    constructor() {
        // 초기 설정이 필요한 경우
    }

    /**
     * 에러 페이지로 이동합니다.
     * @returns {void}
     */
    goToErrorPage() {
        window.location.href = "/error";
        // 실제 페이지 이동 로직을 구현
    }

    /**
     * 에러 메시지를 기록합니다.
     * @param {string} message - 기록할 에러 메시지.
     * @returns {void}
     */
    logError(message) {
        console.error(`${window.location.pathname} : ${message}`);
        // 실제 로깅 로직 구현
    }

    // 필요한 추가 메서드를 여기에 추가
}
