// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` @media (prefers-color-scheme: dark) {

 }
 
 @media (prefers-color-scheme: light) {

 } 

`, "",{"version":3,"sources":["webpack://./src/pages/test/TestPage.css"],"names":[],"mappings":"CAAC;;CAEA;;CAEA;;CAEA","sourcesContent":[" @media (prefers-color-scheme: dark) {\n\n }\n \n @media (prefers-color-scheme: light) {\n\n } \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
