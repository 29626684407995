// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (prefers-color-scheme: dark) {
  table thead  {
    background-color: var(--bg_contents_box_dark);
  }
}

@media (prefers-color-scheme: light) {
  table thead  {
    background-color: var(--bg_contents_box_light);
  }
}


table thead  {
  position: sticky;
  top: 0;
  z-index: 1; 
}
`, "",{"version":3,"sources":["webpack://./src/components/table/TableComponent.css"],"names":[],"mappings":";AACA;EACE;IACE,6CAA6C;EAC/C;AACF;;AAEA;EACE;IACE,8CAA8C;EAChD;AACF;;;AAGA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ","sourcesContent":["\n@media (prefers-color-scheme: dark) {\n  table thead  {\n    background-color: var(--bg_contents_box_dark);\n  }\n}\n\n@media (prefers-color-scheme: light) {\n  table thead  {\n    background-color: var(--bg_contents_box_light);\n  }\n}\n\n\ntable thead  {\n  position: sticky;\n  top: 0;\n  z-index: 1; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
