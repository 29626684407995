import React,{useState,useEffect} from 'react';
import '../../App.css';
import { Route, Routes } from 'react-router-dom';

function LoginPage() {
  const [userId, setUserId] = useState('');
  const [password , setPassword] = useState('');

  const handleLogin = () => {
    if(userId === '1' && password === '1'){
      alert('로그인 성공'); 
      window.location.href = '/main.do';
    }
  }

  return (
    <div className="container">
      <input value = {userId} onChange={(e) => setUserId(e.target.value)} type="text" id = "id"/>
      <input value = {password} onChange = {(e)=> setPassword(e.target.value)} type="password" id = "password"/>
      <button onClick={handleLogin}>로그인</button>
    </div>
  );
}

export default LoginPage;
