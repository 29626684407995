// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
  font-family: 'GmarketSansTTFBold';
  src: url('/src/assets/fonts/GmarketSansTTFBold.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansTTFLight';
  src: url('/src/assets/fonts/GmarketSansTTFLight.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansTTFMedium';
  src: url('/src/assets/fonts/GmarketSansTTFMedium.ttf') format('truetype');
}

body {
  height:100vh;
  margin: 0;
  font-family: 'GmarketSansTTFMedium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {width: 10px;}
*::-webkit-scrollbar-track {background: #E5E5E5; border-radius: 10px;}
*::-webkit-scrollbar-thumb {background: #BFBFBF; border-radius: 10px;}
*::-webkit-scrollbar-thumb:hover {background: #A1A1A1;} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;yDA+ByD","sourcesContent":["/* @font-face {\n  font-family: 'GmarketSansTTFBold';\n  src: url('/src/assets/fonts/GmarketSansTTFBold.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'GmarketSansTTFLight';\n  src: url('/src/assets/fonts/GmarketSansTTFLight.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'GmarketSansTTFMedium';\n  src: url('/src/assets/fonts/GmarketSansTTFMedium.ttf') format('truetype');\n}\n\nbody {\n  height:100vh;\n  margin: 0;\n  font-family: 'GmarketSansTTFMedium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n*::-webkit-scrollbar {width: 10px;}\n*::-webkit-scrollbar-track {background: #E5E5E5; border-radius: 10px;}\n*::-webkit-scrollbar-thumb {background: #BFBFBF; border-radius: 10px;}\n*::-webkit-scrollbar-thumb:hover {background: #A1A1A1;} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
