
import React, { useState } from 'react';
import '../../App.css';
import '../../styles/public.css';
import { Pagination, Skeleton } from 'antd';
import './IntegratedSearch.css';

/**
 * 통합 검색 컴포넌트
 *
 * @component
 * @param {Object} props - 컴포넌트의 props
 * @param {string} props.placeholder - 검색 입력 필드의 placeholder 텍스트
 * @param {React.ReactNode} props.filterComponent - 필터 컴포넌트
 * @param {function} props.handleClickSearch - 검색 버튼 클릭 핸들러
 * @returns {JSX.Element} IntegratedSearch 컴포넌트
 */
function IntegratedSearch({searchKeyword, handleChangeSearchKeyword, showSearchFilter, handleShowSearchFilter, placeholder, filterComponent, handleClickSearch}) {


    return (
        <div className="intergrated_search_wrap" style={{ position: 'relative' }}>
            <input value = {searchKeyword} onChange = {handleChangeSearchKeyword} type="text" id="userSearchKeyword"  placeholder={placeholder} />
            <div id="total_search_img_div" onClick ={handleClickSearch}>
                <img className="search_icon" alt="Search" />
            </div>
            <img className="search_filter" alt="Search" onClick={handleShowSearchFilter} />
            <div
                className="filter_area"
                style={{maxHeight: showSearchFilter ? '500px' : '0'}}>
                <div className="content_area" style={{ opacity: showSearchFilter ? 1 : 0, transition: 'opacity 0.6s ease' }}>
                    {filterComponent}
                </div>
            </div>
        </div>
    );
}

export default IntegratedSearch;
