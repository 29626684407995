import { api } from '../common/customAxios';

export const logsApi = {
    //앱 API 로그 리스트 조회
    fetchAppApiLogList: async ({ page, pageSize = 20, requestType }) => {
        try {
            const response = await api.get('api/v1/logs/app/restful', {
                page: page,
                pageSize: pageSize,
                requestType : requestType
            });
            if (response.data.success) {
                console.log(response.data.resultData);
                return response.data.resultData;
            } else {
                throw new Error("Response success is not true");
            }
        } catch (error) {
            console.error("Error occurred:", error);
            throw error; // 에러를 상위로 전달
        }
    },
    //API API OverView 현황 조회
    fetchTrafficOverview: async ({startDate, endDate, searchKeyword}) => {
        try {
            const response = await api.get('api/v1/logs/overview', {
                searchKeyword : searchKeyword,
                startDate : startDate,
                endDate : endDate
            });
            if (response.data.success) {
                console.log(response.data.resultData)
                return response.data.resultData;
            } else {
                throw new Error("Response success is not true");
            }
        } catch (error) {
            console.error("Error occurred:", error);
            throw error; // 에러를 상위로 전달
        }
    },
    fetchWebApiLogList: async ({ page, pageSize = 20, searchKeyword, startDate, endDate }) => {
        try {
            const response = await api.get('api/v1/logs/web/restful', {
                page: page,
                pageSize: pageSize,
                searchKeyword : searchKeyword,
                startDate : startDate,
                endDate : endDate
            });

            console.log('dfd', response);

            if (response.data.success) {
                
                console.log(response);
                return response.data.resultData;
            } else {
                throw new Error("Response success is not true");
            }
        } catch (error) {
            console.error("Error occurred:", error);
            throw error; // 에러를 상위로 전달
        }
    },
    
};
