import React, { useState, useEffect, useMemo } from 'react';
import '../../App.css';
import '../../styles/public.css'
import './LogsPage.css';
import { Layout, Pagination,Breadcrumb,Skeleton } from 'antd';
import TableComponent from '../../components/table/TableComponent';
import { useQuery } from 'react-query';
import { logsApi } from '../../api/logsHandler';
import IntegratedSearch from '../../components/search/IntegratedSearch';

function  RequestOverView({startDate,endDate,searchKeyword}) {

  const { data: overViewData, isLoading :overViewIsLoading, isError:overViewIsError, refetch:overViewRefetch } = useQuery(['fetchTrafficOverview'], () => logsApi.fetchTrafficOverview({ startDate, endDate, searchKeyword }), {
    staleTime: 20000, // 2 minutes
    cacheTime: 20000, // 3 minutes
    onError: (error) => {
      console.error('Error fetching notifications:', error);
    },
    // refetchInterval: 1000, // 1 seconds
    onSuccess: () => {
      // setLastRefetchTime(new Date().toLocaleString());
    }
  });





  //상단 오버뷰 현황 컴포넌트
  const OverViewItem = ({ width, height, color, title, value }) => {
    console.log('value',value)

  if (overViewIsLoading || !value) {
    return (
      <div className="contents_box" style={{ minHeight: '20px', height:85, width:250 }}>
        <div className="dashboard_wrap">
          <div style={{ gap: 6 }}>
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }
    return (
      <div onClick={() => { }}
        className={`contents_box`}
        style={{ minHeight: '20px', height, width, minWidth: width }}>
        <div className="dashboard_wrap">
          <div class="title display_flex" style={{ alignItems: 'center', gap: 6, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
              <span class="label" style={{ background: color, width: 12, height: 12, borderRadius: 25 }} />
              <div style={{ color: '#FFF', marginBottom: 8, fontWeight: 700, paddingTop: 6 }}>{title}</div>
            </div>
            <div style={{ paddingTop: 0 }}>
              <label style={{ fontSize: 12 }}>
                {startDate} ~ {endDate}
              </label>
            </div>
          </div>
          <div style={{ marginTop: 10, color: '#dbdbdb' }}>
            <span>{value.toLocaleString()}</span>
          </div>
        </div>
      </div>
    );
  };




  return (
    <div style={{ display: 'flex', gap: 12 }}>
      <OverViewItem width={240} height={85} color={'#5cb85c'} title={'총 트래픽'} value={overViewData?.overview?.find((item) => item.desc === 'total_traffic').value} />
      <OverViewItem width={250} height={85} color={'#5bc0de'} title={'WEB 트래픽'} value={overViewData?.overview?.find((item) => item.desc === 'web_traffic').value} />
      <OverViewItem width={260} height={85} color={'#5cb85c'} title={'Mobile 트래픽'} value={overViewData?.overview?.find((item) => item.desc === 'mobile_traffic').value} />
      <OverViewItem width={240} height={85} color={'#f0ad4e'} title={'기간별 로그인 사용자'} value={overViewData?.loggined_count} />
      {/* <OverViewItem width = {170} height = {85} color = {'#d9534f'} title = {''}/> */}
    </div>
  );
}

export default RequestOverView;
