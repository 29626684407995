import React, { useState, useEffect, useMemo } from 'react';
import './TestPage.css';
import { Layout, Pagination,Breadcrumb,Skeleton } from 'antd';
import { useQuery } from 'react-query';

function TestPage() {
  return (
    <Layout>
        <webview id="foo"  style = {{width:'1430px', height:'100vh'}} src="https://www.ybe.kr/staff/login.do"/>
    </Layout>
  );
}

export default TestPage;
