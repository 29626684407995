// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` #root > .container {
    font-family: 'GmarketSansTTFMedium' !important;
}
`, "",{"version":3,"sources":["webpack://./src/webview/pages/approval/ApprEditorPage.css"],"names":[],"mappings":"CAAC;IACG,8CAA8C;AAClD","sourcesContent":[" #root > .container {\n    font-family: 'GmarketSansTTFMedium' !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
