import React, { useState, useEffect, useMemo } from 'react';
import '../../App.css';
import '../../styles/public.css'
import './LogsPage.css';
import { Layout, Pagination,Breadcrumb,Skeleton } from 'antd';
import TableComponent from '../../components/table/TableComponent';
import { useQuery } from 'react-query';
import { logsApi } from '../../api/logsHandler';

function AppLogsPage() {
  const pageSize = 25;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //테이블 선택한 row keys
  const [lastRefetchTime, setLastRefetchTime] = useState(new Date().toLocaleString()); //마지막 업데이트 시간
  const [page, setPage] = useState(1); //현재 페이지 번호
  const [requestType, setRequestType] = useState('TOTAL'); //테이블 조회 타입

  const { data: logResult, isLoading, isError, refetch : refetchApiLog } = useQuery(['apiLogList', page, requestType], () => logsApi.fetchAppApiLogList({ page, pageSize, requestType }), {
    staleTime: 0,// 2 minutes
    cacheTime: 0, // 3 minutes
    // retry: 3, // Retry up to 3 times on error
    onError: (error) => {
      console.error('Error fetching notifications:', error);
    },
    refetchInterval: 2500, // 1 seconds
    onSuccess: () => {
      setLastRefetchTime(new Date().toLocaleString());
    }
  });

  const { data: overViewData, isLoading :overViewIsLoading, isError:overViewIsError, refetch:overViewRefetch } = useQuery(['apiLogReqCnt'], () => logsApi.fetchAppApiLogReqCnt({ }), {
    staleTime: 20000, // 2 minutes
    cacheTime: 20000, // 3 minutes
    retry: 3, // Retry up to 3 times on error
    onError: (error) => {
      console.error('Error fetching notifications:', error);
    },
    refetchInterval: 1000, // 1 seconds
    onSuccess: () => {
      // setLastRefetchTime(new Date().toLocaleString());
    }
  });


  const tableColumns = useMemo(() => [
    { label: 'idx', key: 'idx', width: '0%', textAlign: 'left', hidden: true },
    { label: '요청시간', key: 'req_date', width: '7%', textAlign: 'left' },
    { label: '회사명', key: 'company_name', width: '10%', textAlign: 'left' },
    { label: '부서', key: 'dept_name', width: '7%', textAlign: 'left' },
    { label: '이름', key: 'member_name', width: '5%', textAlign: 'left' },
    { label: '요청URL', key: 'req_url', width: '15%', textAlign: 'left' },
    { label: '요청타입', key: 'req_type', width: '5%', textAlign: 'left', 
      formatter: (value) => {
        if(value == 'GET') {
          return <div className = "t_label" style = {{background:'#5bc0de'}}>GET</div>
        }
        if(value == 'POST') {
          return <div className = "t_label" style = {{background:'#5cb85c'}}>POST</div>
        }
        if(value == 'PUT') {
          return <div className = "t_label" style = {{background:'#f0ad4e'}}>PUT</div>
        }
        if(value == 'DELETE') {
          return <div className = "t_label" style = {{background:'#d9534f'}}>DELETE</div>
        }
    }},
    { label: '기기정보', key: 'device_info', width: '10%', textAlign: 'left' },
  ], []);


  const handleClickOverView = (reqType) => {
      refetchApiLog();
      setRequestType(reqType);
  }

  if(isError) {
    return (
      <Layout style={{ width: '100%', padding: '0 24px 24px' }}>
        <div style = {{color:'blue'}}>오류</div>
      </Layout>
    );
  }

  const OverViewItem = ({ width, height, color, title }) => {
    if (overViewIsLoading) {
      return (
        <div className="contents_box" style={{ minHeight: '20px', height, width }}>
          <div className="dashboard_wrap">
            <div style={{ gap: 6 }}>
              <Skeleton />
            </div>
          </div>
        </div>
      );
    }

    const item = overViewData?.find(v => v.req_type === title);
    return (
      <div 
          onClick={() => handleClickOverView(item.req_type)} 
          className={`contents_box ${requestType === title ? 'selected' : ''}`} 
          style={{ minHeight: '20px', height, width }}
          >
      <div className="dashboard_wrap">
        <div style={{display:'flex', flexDirection:'column'}}>
          <div className="label" style={{ background: color,marginBottom:8 }}>{title}</div>
            <span>{item ? `${item.total_count} 회` : '0 회'}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout style={{padding:'0px 40px' , overflow:'auto'}}>
      <Breadcrumb style={{ margin: '16px 0'}}>
          <Breadcrumb.Item>실시간 로그</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
      </Breadcrumb>
      <div className = "first_wrap" style = {{display:'flex',flexDirection:'column', justifyContent:'flex-start'}}>
        <span className = "title_text" style = {{marginBottom:8}}>Request OverView</span>
        <div style = {{display:'flex', gap :12}}>
          <OverViewItem width = {150} height = {85} color = {'#5cb85c'} title = {'TOTAL'}/>
          <OverViewItem width = {150} height = {85} color = {'#5bc0de'} title = {'GET'}/>
          <OverViewItem width = {150} height = {85} color = {'#5cb85c'} title = {'POST'}/>
          <OverViewItem width = {150} height = {85} color = {'#f0ad4e'} title = {'PUT'}/>
          <OverViewItem width = {170} height = {85} color = {'#d9534f'} title = {'DELETE'}/>
        </div>
      </div>
      <div className = "search_wrap">
        <div style = {{marginBottom:20}}>
          {/* <IntegratedSearch handleSearch = {}/> */}
        </div>
      </div>
      <div id ="api_logs_table" className = "contents_box" style = {{height: 'calc(100% - 280px)'} }>
        {
          isLoading ? 
            <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
                <Skeleton active />
                <Skeleton active />
            </div> :
            <>
              <TableComponent
                  showCheckbox={false}
                  columns={tableColumns}
                  data={logResult?.data?.list || []}
                  loading={isLoading}
                  rowSelection={{
                  selectedRowKeys,
                  onChange: (keys) => setSelectedRowKeys(keys),
              }}/>
              {logResult?.data?.list?.length > 0 && (
                <Pagination
                  current={page}
                  style = {{
                    display:'flex',
                    alignItems:'center',
                    height:'50px', 
                    marginTop:20
                  }}
                  pageSize={pageSize}
                  total={logResult?.data?.total || 0}
                  onChange={(page) => setPage(page)}
                />
              )}
           </>
        }
      </div>
    </Layout>
  );
}

export default AppLogsPage;
